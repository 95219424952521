<template>
	<div v-if="show_form" class="row">
		<div class="col-6 from-group">
    		<label class="col-form-label">{{ $t('monte.etalon') }} *</label>
			<span class="ml-2" v-if="form.stallion && form.stallion.horse_id">
				<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: form.stallion.horse_id }}">
					<font-awesome-icon :icon="['fal', 'link']" />
				</router-link>
			</span>
            <e-select
                id="stallion"
                track-by="horse_id"
                label="horse_nom"
                v-model="form.stallion"
                :placeholder="$t('monte.selectionnez_etalon')"
                :selectedLabel="$t('global.selected_label')"
                :options="stallions"
                :searchable="true"
                :allow-empty="true"
                :show-labels="false"
            />
		</div>
		<div class="col-6 from-group">
    		<label for="mare" class="col-form-label">{{ $t('monte.jument') }}</label>
			<input class="form-control" type="text" v-model="form.semenceexpedition_mare" id="mare">
		</div>
		<div class="col-6 from-group">
    		<label class="col-form-label">{{ $t('monte.congelation.demandeur') }} *</label>
			<span class="ml-2" v-if="form.demandeur && form.demandeur.tiers_id">
				<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: form.demandeur.tiers_id }}">
					<font-awesome-icon :icon="['fal', 'link']" />
				</router-link>
			</span>
			<e-select
				id="tiers"
				track-by="tiers_id"
				label="tiers_rs"
				:placeholder="$t('monte.congelation.selectionner_tiers')"
				:options="proprietaires"
				:searchable="true"
				:show-labels="false"
				v-model="form.demandeur"
			>
				<template slot="option" slot-scope="{ option }">
					{{ option.tiers_rs }}
					<span v-if="option.tags.length > 0" class="tag-manage tag-select"> <span v-for="tag in option.tags" :key="tag.tags_id" :style="'background-color:'+tag.tags_color" class="tags"></span> </span>
				</template>
				<template slot="singleLabel" slot-scope="{ option }">
					{{ option.tiers_rs }}
					<span v-if="option.tags.length > 0" class="tag-manage tag-select"> <span v-for="tag in option.tags" :key="tag.tags_id" :style="'background-color:'+tag.tags_color" class="tags"></span> </span>
				</template>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>
		</div>
		<div class="col-6 from-group">
    		<label class="col-form-label">{{ $t('monte.congelation.destinataire') }} *</label>
			<span class="ml-2" v-if="form.destinataire && form.destinataire.tiers_id">
				<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: form.destinataire.tiers_id }}">
					<font-awesome-icon :icon="['fal', 'link']" />
				</router-link>
			</span>
			<SearchTiers 
				:tiers_selected.sync="form.destinataire"
				:preselected="form.destinataire"
				:with_tags="true"
			/>
		</div>
		<div class="col-6 from-group">
    		<label for="client" class="col-form-label">{{ $t('monte.congelation.client') }}</label>
			<input class="form-control" type="text" v-model="form.semenceexpedition_client" id="client">
		</div>
		<div class="col-6 form-group">
			<label for="contract_num">{{ $t("monte.congelation.contract_num") }}</label>
			<input class="form-control" type="text" v-model="form.semenceexpedition_contract_num" id="contract_num">
		</div>
		<div class="col-6 form-group">
			<label for="qte">{{ $t("monte.congelation.qte") }} *</label>
			<b-form-checkbox class="d-inline" style="float:right" switch v-model="form.semenceexpedition_dose" id="dose">
				{{ $t("monte.congelation.dose") }}
			</b-form-checkbox>
			<input class="form-control" type="number" v-model="form.semenceexpedition_qte" id="qte">
			<span v-if="stock_available.nb_stock && !processing_stock" style="float:right">{{ $t('monte.congelation.stock') }} : {{ stock_available.nb_stock }} - {{ $t('monte.congelation.stock_theorique') }} : {{ stock_available.nb_theorique }} </span>
		</div>
		<div v-if="!form.semenceexpedition_dose" class="col-6 form-group">
			<label for="qte">{{ $t("monte.congelation.norme") }}</label>
			<e-select
				v-model="form.norme"
				id="protocole_id"
				track-by="protocole_id"
				label="protocole_label"
				:placeholder="$t('monte.congelation.selectionner_protocole')"
				:selectedLabel="$t('global.selected_label')"
				:options="protocoles"
				:searchable="true"
				:show-labels="false"
				:group-select="false"
			>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>
		</div>
		<div v-else class="col-6">
			
		</div>
		<div class="col-6 form-group">
			<label for="date">{{ $t("monte.congelation.date_expedition") }} *</label>
			<e-datepicker id="date" v-model="form.semenceexpedition_expedition_date" mode="date"></e-datepicker>
		</div>
		<div class="col-3 form-group">
			<label for="saturday">{{ $t("monte.congelation.saturday") }}</label>
			<b-form-checkbox v-model="form.semenceexpedition_saturday" id="saturday" />
		</div>
		<div class="col-3 form-group">
			<label for="invoice_group">{{ $t("monte.congelation.invoice_group") }}</label>
			<b-form-checkbox v-model="form.semenceexpedition_invoice_group" id="invoice_group" />
		</div>
		<div class="col-12 form-group">
			<label for="commentaire">{{ $t("monte.congelation.commentaire") }}</label>
			<e-editor-froala
				identifier="semenceexpedition_commentaire"
				:placeholder="getTrad('monte.congelation.commentaire')"
				:content="commentaire"
				@change="updateCommentaire"
				:moreText="[ 'bold', 'italic', 'strikeThrough', 'underline', 'undo', 'redo', 'formatUL', 'formatOL', 'clearFormatting' ]"
			></e-editor-froala>
		</div>
		<div class="col-12">
			<h3>{{ $t('compta.articles') }}</h3>
			<div class="d-flex">
				<e-select
					v-model="article_selected"
					id="articles_id"
					track-by="articles_id"
					label="articles_label"
					:placeholder="$t('monte.congelation.selectionner_article')"
					:selectedLabel="$t('global.selected_label')"
					:options="articles"
					:searchable="true"
					:allow-empty="false"
					:show-labels="false"
					:group-select="false"
					:loading="loading_articles"
					@input="addArticle"
				>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div v-if="expedition_articles.length > 0" class="row mt-1">
				<div class="col-3">
					<label for="articles_label">{{ $t('acte.article') }}</label>
				</div>
				<div class="col-1">
					<label for="articles_ht">{{ $t('invoice.invoicedetails_htunit') }}</label>
				</div>
				<div class="col-2">
					<label for="remise">{{ $t('acte.remise') }}</label>
				</div>
				<div class="col-1">
					<label for="qte">{{ $t('acte.quantite') }}</label>
				</div>
				<div class="col-4">
					<label for="commentaire">{{ $t("monte.congelation.commentaire") }}</label>
				</div>
				<div class="col-1">
					<label>{{ $t('global.supprimer') }}</label>
				</div>
			</div>
			<div v-for="(article, index) in expedition_articles" class="row mt-2" :key="index">
				<div class="col-3">
					<b-input type="text" :disabled="true" class="form-control" v-model="article.article.articles_label" />
				</div>
				<div class="col-1">
					<PriceInput v-model="article.expeditionarticles_ht"/>
				</div>
				<div class="col-2">
					<div class="d-flex">
						<b-input type="text" class="form-control" id="remise" v-model="article.expeditionarticles_discount_value" />
						<e-select
							v-model="article.expeditionarticles_discount_type"
							id="discount_type"
							track-by="invoicediscounttype_id"
							label="invoicediscounttype_label"
							:selectedLabel="$t('global.selected_label')"
							:options="discount_type"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ $t(option.invoicediscounttype_label) }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
				</div>
				<div class="col-1">
					<b-input type="number" class="form-control" v-model="article.expeditionarticles_quantite" />
				</div>
				<div class="col-4">
					<b-input type="text" class="form-control" v-model="article.expeditionarticles_commentaire" />
				</div>
				<div class="col-1">
					<b-button @click.prevent="removeArticle(index)"><font-awesome-icon :icon="['fal', 'trash-alt']" /></b-button> 
				</div>
			</div>
		</div>
		<div v-if="expedition" class="col-12 text-center">
			<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm(false)">{{ $t('action.modifier') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
		</div>
		<div v-else class="col-12 text-center">
			<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm(false)">{{ $t('action.ajouter') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
			<a class="btn btn-secondary mt-3 ml-2" href="#" @click.prevent="checkForm(true)">{{ $t('action.ajouter_et_nouveau') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'plus']" /></a>
		</div>

		<b-modal ref="modal-baf" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.facturation') }}
			</template>

			<template slot="default">
            	<b>{{ $t('monte.congelation.question_baf') }}</b>

            	<div class="row text-center mt-2">
            		<div class="col-12">
			            <b-button variant="primary" @click="createBons">
			                <font-awesome-icon v-if="processing_baf" :icon="['fal', 'spinner']" pulse class="mr-1"/><font-awesome-icon v-else :icon="['fal', 'save']" class="mr-1"/>{{ $t('monte.congelation.create_baf') }}
			            </b-button>
			            <b-button variant="secondary" class="ml-2" @click="next">
			                <font-awesome-icon :icon="['far', 'times']" class="mr-1"/>{{ $t('global.non') }}
			            </b-button>
			        </div>
            	</div>
			</template>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Horse from '@/mixins/Horse'
	import Congelation from "@/mixins/Congelation.js"
	import Article from "@/mixins/Article.js"
	import Invoice from "@/mixins/Invoice.js"
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'AddCommande',
		mixins: [Horse, Congelation, Article, Invoice, Shutter],
		props: ['horse_id', 'expedition'],
		data () {
			return {
				form: {
					stallion: null,
					semenceexpedition_mare: '',
					demandeur: null,
					destinataire: null,
					semenceexpedition_client: null,
					semenceexpedition_contract_num: null,
					semenceexpedition_dose: false,
					semenceexpedition_qte: 1,
					norme: null,
					semenceexpedition_expedition_date: new Date(),
					semenceexpedition_commentaire: '',
					semenceexpedition_invoice_group: false,
					semenceexpedition_saturday: false
				},
				stallions: [],
				protocoles: [],
				articles: [],
				article_selected: null,
				expedition_articles: [],
				discount_type: [],
				processing: false,
				loading_articles: false,
				and_new: false,
				processing_baf: false,
				expedition_local: null,
				show_form: true,
				proprietaires: [],
				stock_available: {},
				processing_stock: false,
				commentaire: ''
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				await this.load_discount()

				if(this.expedition) {
					this.show_form = false
					this.expedition_local = this.expedition
					this.form.semenceexpedition_mare = this.expedition.semenceexpedition_mare
					this.form.destinataire = this.expedition.destinataire
					this.form.semenceexpedition_client = this.expedition.semenceexpedition_client
					this.form.semenceexpedition_contract_num = this.expedition.semenceexpedition_contract_num
					this.form.semenceexpedition_dose = this.expedition.semenceexpedition_dose
					this.form.semenceexpedition_qte = this.expedition.semenceexpedition_qte
					this.form.semenceexpedition_expedition_date = new Date(this.expedition.semenceexpedition_expedition_date)
					this.form.semenceexpedition_commentaire = this.expedition.semenceexpedition_commentaire
					this.commentaire = this.expedition.semenceexpedition_commentaire
					this.form.semenceexpedition_invoice_group = this.expedition.semenceexpedition_invoice_group
					this.form.semenceexpedition_saturday = this.expedition.semenceexpedition_saturday

					this.expedition_articles = this.expedition.articles.map(article => ({
						expeditionarticles_article: article.article.articles_id,
						article: article.article,
						expeditionarticles_ht: article.expeditionarticles_ht / 100,
						expeditionarticles_quantite: article.expeditionarticles_quantite,
						expeditionarticles_discount_value: article.expeditionarticles_discount_value / 100,
						expeditionarticles_discount_type: this.discount_type.find(discount => discount.invoicediscounttype_id == article.expeditionarticles_discount_type) ?? this.discount_type[0],
						expeditionarticles_commentaire: article.expeditionarticles_commentaire
					}))
					this.$nextTick(()=> {
						this.show_form = true
					})
				}

				this.load_stallion()
				this.load_protocoles()
				this.load_articles()
			},

			async load_stallion() {
				this.stallions = await this.getHorsesStallion(false)
				if(this.expedition) {
					this.form.stallion = this.stallions.find(horse => horse.horse_id == this.expedition.semenceexpedition_stallion)
					this.load_proprietaires()
				}
				if(this.horse_id) {
					this.form.stallion = this.stallions.find(horse => horse.horse_id == this.horse_id)
					this.load_proprietaires()
				}
			},

			async load_protocoles() {
				this.protocoles = await this.getAllProtocoles()

				if(this.expedition && this.expedition.semenceexpedition_norme) {
					this.form.norme = this.protocoles.find(pro => pro.protocole_id == this.expedition.semenceexpedition_norme)
				}
			},

			async load_articles() {
				this.loading_articles = true
				this.articles = await this.loadAllArticles()
				this.loading_articles = false
			},

			async load_discount() {
				const discount_type = await this.loadDiscountType()
				let tab = []
				tab.push({
					invoicediscounttype_id: 0,
					invoicediscounttype_label: "-",
					invoicediscounttype_type: "none"
				})

				for(let i = 0; i < discount_type.length; i++) {
					let current = discount_type[i]
					current.invoicediscounttype_label = this.getTrad(current.invoicediscounttype_label+"_short")
					tab.push(current)
				}

				this.discount_type = tab
			},

			async load_proprietaires() {
				if(this.form.stallion.horse_id) {
					this.proprietaires = await this.getStallionLotsProprietaires(this.form.stallion.horse_id)

					if(this.expedition && this.expedition.semenceexpedition_demandeur) {
						this.form.demandeur = this.proprietaires.find(pro => pro.tiers_id == this.expedition.semenceexpedition_demandeur)
					}

					if(this.form.demandeur) {
						const is_in = this.proprietaires.findIndex(pro => pro.tiers_id == this.form.demandeur.tiers_id)
						if(is_in == -1) {
							this.form.demandeur = null
						}
					}
				}
			},

			addArticle() {
				if(!this.article_selected) {
					return false
				}

				this.expedition_articles.push({
					expeditionarticles_article: this.article_selected.articles_id,
					article: this.article_selected,
					expeditionarticles_ht: this.article_selected.articles_ht,
					expeditionarticles_quantite: 1,
					expeditionarticles_discount_value: 0,
					expeditionarticles_discount_type: this.discount_type[0],
					expeditionarticles_commentaire: ''
				})

				this.article_selected = null
			},

			removeArticle(index) {
				this.expedition_articles.splice(index, 1)
			},

			async checkForm(and_new = false) {
				if(!this.form.stallion || !this.form.demandeur || !this.form.destinataire || this.processing) {
					return false
				}

				this.processing = true
				const params = {
					semenceexpedition_stallion: this.form.stallion.horse_id,
					semenceexpedition_mare: this.form.semenceexpedition_mare,
					semenceexpedition_demandeur: this.form.demandeur ? this.form.demandeur.tiers_id : null,
					semenceexpedition_destinataire: this.form.destinataire.tiers_id,
					semenceexpedition_client: this.form.semenceexpedition_client,
					semenceexpedition_contract_num: this.form.semenceexpedition_contract_num,
					semenceexpedition_dose: this.form.semenceexpedition_dose,
					semenceexpedition_qte: this.form.semenceexpedition_qte,
					semenceexpedition_norme: this.form.norme ? this.form.norme.protocole_id : null,
					semenceexpedition_expedition_date: this.form.semenceexpedition_expedition_date,
					semenceexpedition_commentaire: this.form.semenceexpedition_commentaire,
					semenceexpedition_invoice_group: this.form.semenceexpedition_invoice_group,
					semenceexpedition_saturday: this.form.semenceexpedition_saturday,
					semenceexpedition_status: 2
				}

				if(this.expedition) {
					await this.editExpedition(this.expedition.semenceexpedition_id, params)
				}
				else {
					this.expedition_local = await this.addExpedition(params)
				}

				if(this.expedition_articles.length > 0)
					await this.addExpeditionArticle(this.expedition_local.semenceexpedition_id, this.expedition_articles)
				this.processing = false
				this.successToast('toast.info_save_succes')

				this.and_new = and_new
				if(this.expedition_articles.length > 0) {
					this.$refs['modal-baf'].show()
				}
				else {
					this.next()
				}
			},

			next() {
				this.$refs['modal-baf'].hide()
				if(this.and_new) {
					this.show_form = false
					this.form = {
						stallion: null,
						semenceexpedition_mare: null,
						demandeur: null,
						destinataire: null,
						semenceexpedition_client: null,
						semenceexpedition_contract_num: null,
						semenceexpedition_dose: false,
						semenceexpedition_qte: 1,
						norme: null,
						semenceexpedition_expedition_date: new Date(),
						semenceexpedition_commentaire: '',
						semenceexpedition_invoice_group: false,
						semenceexpedition_saturday: false
					}
					if(this.horse_id) {
						this.form.stallion = this.stallions.find(horse => horse.horse_id == this.horse_id)
					}
					this.expedition_articles = []
					this.$nextTick(()=> {
						this.show_form = true
					})
				}
				else {
					this.ok()
					this.shutterPanel().close('commande-add')
					this.shutterPanel().close('expedition-edit')
				}
			},

			async createBons() {
				this.processing_baf = true
				
				let params = {details: []}

				this.expedition_articles.forEach(article => {
					params.details.push({
						author_id : article.article.entity_id,
						tiers_id : this.form.demandeur.tiers_id,
						htunit: article.expeditionarticles_ht,
						ht_initial: article.expeditionarticles_ht*100,
						qte : article.expeditionarticles_quantite,
						vat : article.article.articles_vat,
						label : article.article.articles_label,
						description : article.expeditionarticles_commentaire,
						horse_id : this.form.stallion.horse_id,
						articles_id : article.expeditionarticles_article,
						vat_code : null,
						export_vat: 0,
						tiershorseparttype: 0,
						tiershorsepartpart: 0,
						refs_clients: '',
						dossiers_interne: '',
						discount_value: article.expeditionarticles_discount_value,
						discount_type: article.expeditionarticles_discount_type.invoicediscounttype_id != 0 ? article.expeditionarticles_discount_type : null,
						code_analytics: '',
						expedition_id: this.expedition_local.semenceexpedition_id
					})
				})

				const result_invoice_details = await this.createInvoiceDetails(params);

				this.processing_baf = false
				this.$refs['modal-baf'].hide()
				this.next()
			},

			async loadStocks() {
				if(!this.form.stallion || !this.form.demandeur || this.form.semenceexpedition_dose) {
					this.stock_available = {}
					return false
				}

				this.processing_stock = true
				const protocole = this.form.norme ? this.form.norme.protocole_id : ''
				const expedition_id = this.expedition ? this.expedition.semenceexpedition_id : ''
				this.stock_available = await this.getLotsAvailable(this.form.stallion.horse_id, this.form.demandeur.tiers_id, protocole, expedition_id)
				this.processing_stock = false
			},

			updateCommentaire(val) {
				this.form.semenceexpedition_commentaire = val.val
			}
		},

		computed: {
			discount_type_formatted(){
				let tab = []

				tab.push({
					invoicediscounttype_id: 0,
					invoicediscounttype_label: "-",
					invoicediscounttype_type: "none"
				})

				for(let i = 0; i < this.discount_type.length; i++) {
					let current = this.discount_type[i]
					current.invoicediscounttype_label = this.getTrad(current.invoicediscounttype_label+"_short")
					tab.push(current)
				}

				return tab
			}
		},

		watch: {
			'form.semenceexpedition_dose' (val) {
				if(val) {
					const date = new Date()
					date.setDate(date.getDate() + 1)
					this.form.semenceexpedition_expedition_date = date
				}
				this.loadStocks()
			},
			'form.stallion' (val) {
				if(val && val.horse_id) {
					this.load_proprietaires()
				}
				this.loadStocks()
			},
			'form.demandeur' (val) {
				this.loadStocks()
			},
			'form.norme' (val) {
				this.loadStocks()
			}
		},

		components: {
			SearchJument : () => import('@/components/Contract/SearchJument'),
			SearchTiers : () => import('@/components/Contract/SearchTiers'),
			PriceInput: () => import('GroomyRoot/components/Inputs/PriceInput')
		}
	}

</script>